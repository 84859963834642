var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('card-header',{attrs:{"title":"New Photo","icon":"fa-plus"}}),_c('card-body',[_c('card-list',[_c('photo-form',{attrs:{"card":_vm.card,"photo":_vm.newPhoto}}),(_vm.newPhoto.file)?_c('container-list-item',[_c('data-row',{attrs:{"title":"File"}},[_vm._v(_vm._s(_vm.newPhoto.file_name))]),_c('data-row',{attrs:{"title":"Hash"}},[_vm._v(_vm._s(_vm.newPhoto.file))]),_c('data-row',{attrs:{"title":"Type"}},[_vm._v(_vm._s(_vm.newPhoto.file_mime))])],1):_vm._e(),_c('container-list-item',{attrs:{"padded":""}},[_c('file-pond',{ref:"pond",attrs:{"name":"file","label-idle":"Drop or click to add photo...","allow-multiple":false,"allowRevert":false,"accepted-file-types":"image/jpeg, image/png, image/gif","files":_vm.files,"server":{
                                url: _vm.apiUrl,
                                timeout: 7000,
                                process: {
                                    url: 'api/trip_photos/upload',
                                    method: 'POST',
                                    headers: {
                                        'Authorization': _vm.token
                                    },
                                    withCredentials: false
                                }
                            }},on:{"init":_vm.handleFilePondInit,"processfile":_vm.handleFilePondProcess}})],1)],1)],1),_c('card-footer',{staticClass:"pt-3 pb-3 has-text-right"},[_c('b-button',{attrs:{"type":"is-primary","size":"is-small","disabled":!_vm.newPhoto.file},on:{"click":_vm.submit}},[_c('i',{staticClass:"fas fa-plus mr-3"}),_vm._v("New Photo")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }